<template>
    <div class="add-new-item">
        <title-bar
            submitBtn
            :submitBtnTitle="submitBtnTitle"
            :title="title"
            @submitPressed="submitPressed"
        />
        <items-form
            :itemObject="item"
            :action="action"
            :baseUrl="baseUrl"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ItemsForm from './ItemsForm.vue'

export default {
    components: {
        TitleBar,
        ItemsForm
    },
    props: {
        itemObject: { Object, required: true },
        baseUrl: { type: String, required: false, default: '/items' },
        submitBtnTitle: { type: String, required: true },
        title: { type: String, required: true },
    },
    data() {
        return {
          item: this.itemObject,
          action: null,
        }
    },
    created() {
      this.load();
    },
    methods: {
      load() {
        this.item.statusSelect = {
          label: this.$helper.getEnumTranslation("ITEM_STATUS", 'NO_ACTION', this.$i18n.locale),
          id: 'NO_ACTION'
        }
      },
      submitPressed() {
          this.action = 'add'
      }
    }
}
</script>
